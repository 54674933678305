import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InsuranceProviderModel } from '..';
import { catchError } from 'rxjs/operators';

@Injectable()

export class InsuranceProviderService {
    private authUrl = `${environment.assetUrl}`;

    constructor(
        private http: HttpClient
    ){}

    createInsuranceProvider(insuranceModel: InsuranceProviderModel): Observable<any> {
        return this.http.post(`${this.authUrl}/admin/config/insurance-supplier`, insuranceModel)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    getAllInsuranceProviders(): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/config/insurance-supplier`)
            .pipe(catchError((error: any) => throwError(error)));
    }
    getInsurance(id: number): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/config/insurance-supplier/${id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getInsuranceProvidersPerPage(page, limit, filterObject: any): Observable<any> {
        let query = `?page=${page}&limit=${limit}`
            if(filterObject.name != null) {
                query = `${query}&name=${filterObject.name}`
            }
        return this.http.get(`${this.authUrl}/admin/config/insurance-supplier${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateInsuranceProvider(insuranceProvider: InsuranceProviderModel): Observable<any> {
    return this.http.put(`${this.authUrl}/admin/config/insurance-supplier/${insuranceProvider.id}`, insuranceProvider)
        .pipe(catchError((error: any) => throwError(error)));
    }
    deleteInsuranceProvider(insuranceProviderId: number): Observable<any> {
        return this.http.delete(`${this.authUrl}/admin/config/insurance-supplier/${insuranceProviderId}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
}


