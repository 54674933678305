import { throwError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CompanyModel } from '../models';
import { catchError } from 'rxjs/operators';

@Injectable()

export class CompanyService {
    private authUrl = `${environment.assetUrl}`;

    constructor(private router: Router,
                private http: HttpClient) {
                }

    createCompany(company: CompanyModel): Observable<any>{
        return this.http.post(`${this.authUrl}/admin/company`, company).pipe(
            catchError((error: any) => throwError(error)));
    }

    getCompanies(pageNumber?: number, limit?: number, filterObject?: any): Observable<any>{
        let query = `?page=${pageNumber}&limit=${limit}`
        if(filterObject.name != null) {
            query = `${query}&name=${filterObject.name}`
        }
        return this.http.get(`${this.authUrl}/admin/company${query}`).pipe(
            catchError((error: any) => throwError(error)));
    }

    getCompaniesWithoutLimits(): Observable <any> {
        return this.http.get(`${this.authUrl}/admin/company`).pipe(
            catchError((error: any) => throwError(error)));
    }

    getSingleCompany(companyId: number): Observable<any>{
        return this.http.get(`${this.authUrl}/admin/company/${companyId}`)
        .pipe(
            catchError((error: any) => throwError(error)));
    }

    getReportByCompany(company_id: number): Observable<any> {
        let query = `${this.authUrl}/admin/company/${company_id}/billing`;
        return this.http.get(`${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateReport(company_id: number, billingInfo: any): Observable<any> {
        let query = `${this.authUrl}/admin/company/${company_id}/billing`;
        return this.http.put(`${query}`, billingInfo)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getMonthlyReport(): Observable<any>{
        return this.http.get(`${this.authUrl}/admin/company/billing/month`)
        .pipe(catchError((error: any) => throwError(error)));
    }

    getBillingOverview(): Observable<any> {
      return this.http.get(`${this.authUrl}/admin/company/billing/overview`)
      .pipe(catchError((error: any) => throwError(error)));
    }

    deleteCompany(companyId: number): Observable<any> {
        return this.http.delete(`${this.authUrl}/admin/company/${companyId}`)
            .pipe(
                catchError((error: any) => throwError(error)));
    }

    updateCompany(company: CompanyModel): Observable<any> {
        return this.http.put(`${this.authUrl}/admin/company/${company.id}`, company)
            .pipe(
                catchError((error: any) => throwError(error)));
    }

}
