import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserModel } from '../models';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()

export class UserService {
    private authUrl = `${environment.authUrl}`;
    private gateWay = `${environment.assetUrl}`;
    private remarkUrl = `${environment.reUrl}`;
    constructor(
        private http: HttpClient
    ) {}

    createUser(user: UserModel, lissaApp?: string): Observable<any> {
        return this.http.post(`${this.authUrl}/user/${lissaApp}`, user)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getAllUsers(pageNumber: number, limit: number, filterObject?: any): Observable <any> {
      let query = `?page=${pageNumber}&limit=${limit}`;
      if (filterObject.name && filterObject.name != null) {
            query = `${query}&name=${filterObject.name}`;
        }
      return this.http.get(`${this.authUrl}/user/search${query}`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getUserById(userId: number): Observable<UserModel> {
      return this.http.get<UserModel>(`${this.authUrl}/user/${userId}`)
      .pipe(catchError((error: any) => throwError(error)));
    }

    updateUser(userId: number, user: UserModel): Observable <any> {
        return this.http.patch(`${this.authUrl}/user/${userId}`, user)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getUsersPerCompany(pageNumber: number, limit: number): Observable <any> {
        return this.http.get(`${this.gateWay}/auth/user/all?page=${pageNumber}&limit=${limit}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    createServiceManager(manager): Observable<any>{
        return this.http.post(`${this.authUrl}/user/service-manager`, manager)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getServiceManagers(pageNumber?: number, limit?: number): Observable<any>{
      return this.http.get(`${this.gateWay}/auth/user/all?role=service-manager${pageNumber ? "&page=" + pageNumber : ""}${limit ? "&limit=" + limit : ""}`)
      .pipe(catchError((error: any) => throwError(error)));
    }
    createRemarketingManagers(admin): Observable<any>{
      return this.http.post(`${this.authUrl}/user/remarketing-admin`, admin)
      .pipe(catchError((error: any) => throwError(error)));
  }
    getRemarketingManagers(pageNumber?: number, limit?: number): Observable<any>{
      return this.http.get(`${this.remarkUrl}/user/all?role=rm-admin${pageNumber ? "&page=" + pageNumber : ""}${limit ? "&limit=" + limit : ""}`)
      .pipe(catchError((error: any) => throwError(error)));
    }

    deleteUser(email: string): Observable<any> {
      return this.http
        .delete(`${this.authUrl}/user/${email}`)
        .pipe(catchError((error: any) => throwError(error)));
    }

}
