import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IStyleConfigurator } from '..';

@Injectable()

export class StyleConfiguratorService {
  private url = `${environment.assetUrl}/admin/configuration`;

  constructor(
    private http: HttpClient
  ) { }

  createConfiguration(configuration: IStyleConfigurator): Observable<IStyleConfigurator> {
    return this.http.post<IStyleConfigurator>(`${this.url}`, configuration).pipe(
      catchError((error: any) => throwError(error)));
  }

  updateConfiguration(confID: string, configuration: IStyleConfigurator): Observable<IStyleConfigurator> {
    return this.http.patch<IStyleConfigurator>(`${this.url}/${confID}`, configuration).pipe(
      catchError((error: any) => throwError(error)));
  }

  getConfigurations(): Observable<IStyleConfigurator> {
    return this.http.get<IStyleConfigurator>(`${this.url}`).pipe(
      catchError((error: any) => throwError(error)));
  }

  getConfigByID(confID: number): Observable<IStyleConfigurator> {
    return this.http.get<IStyleConfigurator>(`${this.url}?conf_id=${confID}`).pipe(
      catchError((error: any) => throwError(error)));
  }
}
