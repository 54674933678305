import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { DateFormat } from './dateFormat.pipe';
import { AmountFormtedPipe } from './amount.pipe';

@NgModule({
  declarations: [DateFormat, AmountFormtedPipe],
  imports: [CommonModule],
  exports: [DateFormat, AmountFormtedPipe]
})
export class MainPipe {}
