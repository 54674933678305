import { Injectable } from "@angular/core";
import { throwError, Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BrandModel } from '../models';
import { catchError } from 'rxjs/operators';

@Injectable()

export class BrandService {
    private authUrl = `${environment.assetUrl}`;

    constructor( private http: HttpClient) {}

    createBrand(brand: BrandModel): Observable<any>{
        return this.http.post(`${this.authUrl}/admin/device/brand`, brand).pipe(
            catchError((error: any) => throwError(error)));
    }

    getBrand(): Observable<any> {
      return  this.http.get(`${this.authUrl}/admin/device/brand`).pipe(
          catchError((error: any) => throwError(error)));
    }
    getSingleBrand(brandId: number): Observable<any> {
        return  this.http.get(`${this.authUrl}/admin/device/brand/${brandId}`).pipe(
            catchError((error: any) => throwError(error)));
    }

    getBrandsPerPage(pageNum: number, limit: number, filterObject: any): Observable<any> {
        let query = `?page=${pageNum}&limit=${limit}`
        if(filterObject.name != null) {
            query = `${query}&name=${filterObject.name}`
        }
        return this.http.get(`${this.authUrl}/admin/device/brand${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }

    deleteBrand(brandId: number): Observable <any> {
        return this.http.delete(`${this.authUrl}/admin/device/brand/${brandId}`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    updateBrand(brand: BrandModel): Observable <any> {
        return this.http.put(`${this.authUrl}/admin/device/brand/${brand.id}`, brand)
            .pipe(catchError((error: any) => throwError(error)));
    }
}
