
import { Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthGuard } from './shared';
import { AdminComponent } from './admin/admin.component';
import { AddAdminComponent } from './admin/admin-list/add-admin/add-admin.component';
import { LissaLibraryComponent } from './shared/lissa-library/lissa-library.component';

export const routes: Routes = [
  {
      path: "",
      redirectTo: "/users",
      pathMatch: "full",

  },
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('./admin/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Clients'
        }
      },
      {
         path: 'devices',
        loadChildren: () => import('./admin/devices/devices.module').then(m => m.DevicesModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Devices'
        }
      },
      {
        path: 'service-managers',
        loadChildren: () => import('./admin/service-managers/service-managers.module').then(m => m.ServiceManagersModule)
      },
      {
        path: 'remarketing-managers',
        loadChildren: () => import('./admin/remarketing-managers/remarketing-managers.module').then(m => m.RemarketingManagersModule)
      },
      {
        path: 'brands',
        loadChildren: () => import('./admin/brands/brands.module').then(m => m.BrandsModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Brands'
        }
      },
      {
        path: 'models',
        loadChildren: () => import('./admin/models/models.module').then(m => m.ModelsModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Models'
        }
      },
      {
        path: 'variants',
        loadChildren: () => import('./admin/variants/variants.module').then( m => m.VariantsModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Variants'
        }
      },
      {
        path: 'insurance',
        loadChildren: () => import('./admin/warranties/warranties.module').then(m => m.WasrrantiesModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Insurance'
        }
      },
      {
        path: 'insurance-provider',
        loadChildren: () => import('./admin/insurers/insurers.module').then(m => m.InsurersModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Insurance Provider'
        }
      },
      {
        path: 'billing',
        loadChildren: () => import('./admin/billing-reports/billing-reports.module').then(m => m.BillingReportsModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Billing'
        }
      },
      {
        path: 'providers',
        loadChildren: () => import('./admin/providers/providers.module').then(m => m.ProvidersModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Providers'
        }
      },
      {
        path: 'countries',
        loadChildren: () => import('./admin/countries/countries.module').then(m => m.CountriesModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Countries'
        }
      },
      {
        path: 'all-lissa-users',
        loadChildren: () => import('./admin/admin-list/admin-list.module').then(m => m.AdminListModule ),
        data: {
          breadscrumb: 'Users'
        }
      },
      {
        path: 'features',
        loadChildren: () => import('./admin/features/features.module').then(m => m.FeaturesModule ),
        data: {
          breadscrumb: 'Features'
        }
      },
      {
        path: 'style-configurator',
        loadChildren: () => import('./admin/style-configurator/style-configurator.module').then(m => m.StyleConfiguratorModule),
        data: {
          breadscrumb: 'Style Configurator'
        }
      }
    ]
  },
  {
    path: "",
    component: AuthenticationComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
    {
      path: "lissa-lib",
      component: LissaLibraryComponent
    }


];

