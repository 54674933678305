import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { InsuranceModel } from '../models';
import { catchError } from 'rxjs/operators';

@Injectable()

export class InsuranceService {
    private authUrl = `${environment.assetUrl}`;

    constructor(
        private http: HttpClient
    ) {}
    createInsurance(insuranceModel: InsuranceModel): Observable<any> {
        return this.http.post(`${this.authUrl}/admin/config/insurance`, insuranceModel)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getInsurances(): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/config/insurance`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getInsurancesPerPage(page, limit, filterObject: any): Observable<any> {
        let query = `?page=${page}&limit=${limit}`
        if(filterObject.name != null) {
            query = `${query}&name=${filterObject.name}`
        }
        return this.http.get(`${this.authUrl}/admin/config/insurance${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getInsurance(insurance_id: number): Observable<any>{
        return this.http.get(`${this.authUrl}/admin/config/insurance/${insurance_id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    deleteInsurance(insuranceId: number): Observable <any> {
        return this.http.delete(`${this.authUrl}/admin/config/insurance/${insuranceId}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateInsurance(insurance: InsuranceModel): Observable<any> {
        return this.http.put(`${this.authUrl}/admin/config/insurance/${insurance.id}`, insurance)
        .pipe(catchError((error: any) => throwError(error)));

    }

}
