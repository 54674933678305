import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { DeviceModel } from '../models';

@Injectable()

export class ModelService{

    private authUrl = `${environment.assetUrl}`;

    constructor(private http: HttpClient) {
    }

    getType(): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/device/type`)
        .pipe(catchError ((error: any) => throwError(error)));
    }
    createModel(brandId: number, model: DeviceModel): Observable<any> {
        return this.http.post(`${this.authUrl}/admin/device/brand/${brandId}/model`, model)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    getModel(brandId: number): Observable<any>
    {
        return this.http.get(`${this.authUrl}/admin/device/brand/${brandId}/model`)
        .pipe(catchError ((error: any) => throwError(error)));
    }
    // getModel(): Observable<any> {
    //     return this.http.get(`${this.authUrl}/admin/device/brand/1/model`)
    //         .pipe(catchError ((error: any) => throwError(error)));
    // }

    getModelsWithoutLimit(): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/device/model`)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    getAllModels(pageNumber: number, limit: number, filterObject: any): Observable<any> {
        let query = `page=${pageNumber}&limit=${limit}`;
        if (filterObject && filterObject.device_type_id){
            query = query + `&device_type_id=${filterObject.device_type_id}`;
        }
        if (filterObject && filterObject.brand_id) {
          query = query + `&brand_id=${filterObject.brand_id}`;
        }
        if(filterObject.name != null) {
            query = query + `&name=${filterObject.name}`
        }
        if(filterObject.brand_id) {
          return this.http.get(`${this.authUrl}/admin/device/brand/${filterObject.brand_id}/model?${query}`)
          .pipe(catchError ((error: any) => throwError(error)));
        }
        return this.http.get(`${this.authUrl}/admin/device/model?${query}`)
       .pipe(catchError ((error: any) => throwError(error)));
    }

    getSingleModel(model_id: number): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/device/model/${model_id}`)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    updateModel(model: DeviceModel): Observable<any> {
        return this.http.put(`${this.authUrl}/admin/device/model/${model.id}`, model)
        .pipe(catchError ((error: any) => throwError(error)));
    }
}
