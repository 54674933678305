import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { MainPipe } from './shared/pipes/main-pipe.module';
import { ToastrModule } from 'ngx-toastr';

//COMPONENTS
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { DeleteModalComponent } from './shared/modals/delete-modal/delete-modal.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LissaLibraryComponent, DialogContentDialog } from './shared/lissa-library/lissa-library.component';
import { SidebarComponent } from './admin/sidebar/sidebar.component';
import { NavbarComponent } from './admin/navbar/navbar.component';
import { BreadscrumbComponent } from './shared/breadscrumb/breadscrumb.component';
import { BillingInfoModalComponent } from './shared/modals/billing-info-modal/billing-info-modal.component';


//ROUTING
import { routes } from './app.routing';

//GUARDS //SERVICES
import { AuthGuard, TokenInterceptor, AuthenticationService, CompanyService, BrandService, ModelService} from './shared';
import { InsuranceProviderService } from './shared/services/insurance-provider.service';








@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LissaLibraryComponent,
    AuthenticationComponent,
    SidebarComponent,
    NavbarComponent,
    DialogContentDialog,
    DeleteModalComponent,
    BreadscrumbComponent,
    BillingInfoModalComponent,
    ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MainPipe
  ],
  entryComponents: [
    DialogContentDialog,
    DeleteModalComponent,
    BillingInfoModalComponent
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthenticationService,
    CompanyService,
    BrandService,
    ModelService,
    InsuranceProviderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
