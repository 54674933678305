import { BrandModel } from './brand.model';
import { TypeModel } from './device-type.model';

export class DeviceModel {
    id: number;
    name: string;
    description?: string;
    brand_id: number;
    device_type_id: number;
    brand: BrandModel;
    device_type: TypeModel;
    active: boolean;
}