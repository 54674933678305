import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { VariantsModule } from 'src/app/admin/variants/variants.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeviceVariants } from '../models';

@Injectable({
    providedIn: 'root'
})

export class VariantsService {
    private authUrl = `${environment.assetUrl}`;

    constructor(private http: HttpClient) {}

    createVariants(brandId: number, deviceTypeId: number, variantModule: VariantsModule): Observable<any> {
        return this.http.post(`${this.authUrl}/admin/device/brand/${brandId}/model/${deviceTypeId}/variant`, variantModule)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    getAllVariants(perPage: number, itemsLimit: number, filterObject: any): Observable<any> {
        let query = `?page=${perPage}&limit=${itemsLimit}`;
        if(filterObject.name != null){
            query = `${query}&description=${filterObject.name}`
        }
        return this.http.get(`${this.authUrl}/admin/device/variant${query}`)
        .pipe(catchError ((error: any) => throwError(error)));
    }
    updateVairant(variants: DeviceVariants): Observable<any> {
        return this.http.put(`${this.authUrl}/admin/device/variant/${variants.id}`, variants)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    getSingleVariant(variantId: number): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/device/variant/${variantId}`)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    deleteVariant(variantId: number): Observable<any> {
        return this.http.delete(`${this.authUrl}/admin/device/variant/${variantId}`)
        .pipe(catchError ((error: any) => throwError(error)));
    }

    getSearchVariantsByManufacturersCode( perPage: number, itemsLimit: number, filterObject: any): Observable <any> {
        ///admin/device/variant/unified-variant?name=23123
        let query = `?page=${perPage}&limit=${itemsLimit}`;
        if(filterObject.manufacturer_code != null){
            query = `${query}&name=${filterObject.manufacturer_code}`
        }        
        return this.http.get(`${this.authUrl}/admin/device/variant/unified-variant${query}`)
        .pipe(catchError ((error: any) => throwError(error)));
    }
}
