import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { MobileProviderModel } from '../models';
import { catchError } from 'rxjs/operators';

@Injectable()


export class MobileProviderService {
    private authUrl = `${environment.assetUrl}`;

    constructor(
        private http: HttpClient
    ){}

    createMobileProvider(mobileProvider: MobileProviderModel): Observable<any> {
        return this.http.post(`${this.authUrl}/admin/config/mobile-provider`, mobileProvider)
            .pipe(catchError((error: any) => throwError(error)));
    }
    getMobileProvider(id: number): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/config/mobile-provider/${id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getAllMobileProviders(): Observable<any> {
        return this.http.get(`${this.authUrl}/admin/config/mobile-provider`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getMobProPerPage(pageNum, limit, filterObject: any) {
        let query = `?page=${pageNum}&limit=${limit}`
            if(filterObject.name != null) {
                query = `${query}&name=${filterObject.name}`
            }
        return this.http.get(`${this.authUrl}/admin/config/mobile-provider${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    updateMobileProvider(mobileProvider: MobileProviderModel): Observable<any> {
        return this.http.put(`${this.authUrl}/admin/config/mobile-provider/${mobileProvider.id}`, mobileProvider)
        .pipe(catchError((error: any) => throwError(error)));
    }
    deleteMobileProvider(mobileProviderId: number): Observable<any> {
        return this.http.delete(`${this.authUrl}/admin/config/mobile-provider/${mobileProviderId}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
}
