import { CountriesModel } from './countres.model';

export class CompanyModel {
    id: number;
    name: string;
    uid: number;
    bill?: number;
    billing_information: string;
    price_per_device?: number;
    created_at: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    zip: string;
    invoice_email?: string;
    country_id: number;
    country: CountriesModel;
    devices: number;
    users: number;

}