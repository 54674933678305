import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CountriesModel } from '../models/countres.model';
import { catchError } from 'rxjs/operators';

@Injectable()

export class CountriesService {
    private authUrl = `${environment.assetUrl}`;


    constructor(
        private http: HttpClient
    ) {}
    getSingleCountry(countryId: number): Observable<any>{
        return this.http.get(`${this.authUrl}/admin/config/country/${countryId}`)
        .pipe(catchError ((error: any) => throwError(error)));
    }
    updateCompany(country: CountriesModel): Observable<any> {
        return this.http.put(`${this.authUrl}/admin/config/country/${country.id}`, country)
        .pipe(catchError ((error: any) => throwError(error)));
    }
    createCountry(countriesModel: CountriesModel): Observable<any> {
       return this.http.post(`${this.authUrl}/admin/config/country`, countriesModel)
        .pipe(catchError ((error: any) => throwError(error)));
   }

   getCountries(pageNum: number, limit: number, filterObject: any): Observable<any> {
    let query = `?page=${pageNum}&limit=${limit}`
    if(filterObject.name != null) {
        query = `${query}&name=${filterObject.name}`
    }
       return this.http.get(`${this.authUrl}/admin/config/country${query}`)
        .pipe(catchError ((error: any) => throwError(error)));
   }
   getCountriesWithoutLimit(): Observable<any> {
       return this.http.get(`${this.authUrl}/admin/config/country`)
       .pipe(catchError ((error: any) => throwError(error)));
   }

}
