import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompanyService } from '../../services';

@Component({
  selector: 'app-billing-info-modal',
  templateUrl: './billing-info-modal.component.html',
  styleUrls: ['./billing-info-modal.component.scss']
})
export class BillingInfoModalComponent implements OnInit, AfterViewInit {
  public billing_info;
  public custom_text: string = '';
  public billingForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<BillingInfoModalComponent>,
    private companyService: CompanyService,
    private fb: FormBuilder
  ) { 
    this.billing_info = data;
    this.custom_text = data.text;
  }

  ngOnInit() {
    this.buildBillingForm();
  }
  buildBillingForm() {
    this.billingForm = this.fb.group({
      period_start_date: [],
      price_per_device: []
    })
  }
  updateReport() {
    this.companyService.updateReport(this.billing_info.company_id, this.billingForm.value)
      .subscribe((res) => {
        if(res) {
          this.dialogRef.close();
          // window.location.reload();
        }
      })
  }
  confirm() {
    this.dialogRef.close(true);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      let date = new Date(this.billing_info.period_start_date);
      this.billingForm.patchValue({
        price_per_device: this.billing_info.price_per_device,
        period_start_date: date
      });
    }, 100);
  }

}
