import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleInfo'
})
export class RoleInfoPipe implements PipeTransform {
  private roleMap = {
    'order-admin': { color: 'role-order', module: 'Order' },
    'order-client-admin': { color: 'role-order', module: 'Order' },
    'order-client-manager': { color: 'role-order', module: 'Order' },
    'rm-admin': { color: 'role-rmk', module: 'Remarketing' },
    'service-manager': { color: 'role-service', module: 'Service' },
    'store-admin': { color: 'role-service', module: 'Service' },
    'store-agent': { color: 'role-service', module: 'Service' },
    'service-admin': { color: 'role-service', module: 'Service' }
  };

  transform(role: string, property?: 'color' | 'module'): string | { color: string; module: string } {
    const info = this.roleMap[role] || { color: 'role-asset', module: 'Asset' };
    
    if (property) {
      return info[property];
    }
    
    return info;
  }
}
