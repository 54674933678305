export class UserModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  company_id: number;
  lissa_roles: [];
  lissa_modules: [];
  role: string;
  status: boolean;
  name: string;
}
