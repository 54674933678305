import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor() { }

  imgSrc = [{
    userGray: '../assets/icons/users_icon_gray.svg',
    userWhite: '../assets/icons/dashboard_users_icon.svg',
    devicesGray : '../assets/icons/devices_icon.svg',
    devicesWhite: '../assets/icons/devices_icon_white.svg',
    brandsGray: '../assets/icons/brands_icon.svg',
    brandsWhite: '../assets/icons/brands_icon_white.svg',
    warrantiesGray: '../assets/icons/warranties_icon.svg',
    warrantiesWhite: '../assets/icons/warranties_icon_white.svg',
    modelGray: '../assets/icons/model_icon.svg',
    modelWhite: '../assets/icons/model_icon_white.svg',
    insurersGray: '../assets/icons/insurers_icon.svg',
    insurersWhite: '../assets/icons/insurers_icon_white.svg',
    providersGray: '../assets/icons/providers_icon.svg',
    providersWhite: '../assets/icons/providers_icon_white.svg',
    countryGray: '../assets/icons/country_icon.svg',
    countryWhite: '../assets/icons/country_icon_white.svg',
    variantsGray: '../assets/icons/variants_icon.svg',
    variantsWhite: '../assets/icons/variants_icon_white.svg'
  }];
}
