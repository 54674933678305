import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()

export class FeaturesService {
    private featuresUrl = `${environment.assetUrl}/admin/notification`;


    constructor(
        private http: HttpClient
    ) {}
    createFeatures(features): Observable<any> {
      return this.http.post(`${this.featuresUrl}`, features)
      .pipe(catchError ((error: any) => throwError(error)));
    }
    getFeatures(): Observable<any> {
      return this.http.get(`${this.featuresUrl}`)
      .pipe(catchError ((error: any) => throwError(error)));
    }
    deleteFeatures(feature_id): Observable<any> {
      return this.http.delete(`${this.featuresUrl}/${feature_id}`)
      .pipe(catchError ((error: any) => throwError(error)));
    }
}
